import React from "react";
import MainBanner from "../schedule/MainBanner";
import EventSchedulesThree from "../schedule/EventSchedulesThree";
import Footer from "../Common/Footer";

const ScheduleThree = () => {
  return (
    <>
      <MainBanner />
      <EventSchedulesThree />
      <Footer />
    </>
  );
};

export default ScheduleThree;
