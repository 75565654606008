import React, { useState, useEffect } from "react";

const CountDown = () => {
  const [countdown, setCountdown] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const makeTimer = () => {
    let endTime = new Date("January 1, 2023 17:00:00 PDT");
    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < "10") {
      hours = "0" + hours;
    }
    if (minutes < "10") {
      minutes = "0" + minutes;
    }
    if (seconds < "10") {
      seconds = "0" + seconds;
    }

    setCountdown({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      makeTimer();
    }, 1000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <section className="countdown-area">
      <div className="container">
        <div className="event-countdown">
          <div id="timer">
            <div id="days">
              {countdown.days} <span>Days</span>
            </div>
            <div id="hours">
              {countdown.hours} <span>Hours</span>
            </div>
            <div id="minutes">
              {countdown.minutes} <span>Minutes</span>
            </div>
            <div id="seconds">
              {countdown.seconds} <span>Seconds</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CountDown;
