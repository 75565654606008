import React, { useEffect } from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxButton from "../Shared/LaxButton";

const About = () => {
  useEffect(() => {
    lax.setup();

    const handleScroll = () => {
      lax.update(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll, false);

    return () => {
      document.removeEventListener("scroll", handleScroll, false);
    };
  }, []);

  return (
    <section className="about-area ptb-120 bg-image">
      <div className="container">
        <div className="row h-100 align-items-center">
          <div className="col-lg-6">
            <div className="about-content">
              <span>ÚNETEALEVENTO</span>
              <h2>Traemos el Emprendimiento a Asturias</h2>
              <p>
                Por primera vez, traemos a Asturias a algunos de los mejores
                emprendedores en el panorama nacional para que nos cuenten sus
                experiencias, sus éxitos y sus fracasos.
              </p>

              <p>
                Empresarios de diferentes sectores que han triunfado en cada uno
                de ellos y que, a día de hoy, son grandes referentes. Queremos
                que vivas de primera mano qué es crear una empresa y mantenerla,
                cómo es la interacción con los clientes, los sacrificios que
                conlleva hacer todo esto y por qué estás en el mejor momento
                para hacerlo.
              </p>

            

              <Link to="/s" className="btn btn-primary">
              Leer Más
                <i className="icofont-double-right"></i>
              </Link>

              <Link to="#" className="btn btn-secondary">
              Comprar Ticket
              </Link>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="about-image">
              <img
                src={require("../../assets/images/about1.jpg")}
                className="about-img1"
                alt="about"
              />

              <img
                src={require("../../assets/images/about2.jpg")}
                className="about-img2"
                alt="about"
              />

              <img
                src={require("../../assets/images/shapes/5.png")}
                className="shape-img"
                alt="about"
              />

              
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
