import React, { useState, useEffect } from "react";
import { Link, withRouter, NavLink } from "react-router-dom";
import logo from "../../assets/images/eventnites_logo.png";
import User from "../../assets/images/user.png";
import AuthService from "../../services/auth.service";
import { toast } from "react-toastify";

const Navigation = () => {
  const auth = AuthService.getCurrentUser();
  const [collapsed, setCollapsed] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginForm, setLoginForm] = useState(false);
  const [signupForm, setSignupForm] = useState(false);
  const [loading, setLoading] = useState(false);

  const [userData, setUserData] = useState({
    name: "",
    email: "",
    password: "",
    phoneNo: "",
    address: "",
    role: "user",
  });

  const [loginData, setLoginData] = useState({
    email: "",
    password: "",
    role: "user",
  });

  const toggleNavbar = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const elementId = document.getElementById("navbar");

    const handleScroll = () => {
      if (window.scrollY > 170) {
        elementId.classList.add("is-sticky");
        window.history.pushState("", document.title, window.location.pathname);
      } else {
        elementId.classList.remove("is-sticky");
      }
    };

    document.addEventListener("scroll", handleScroll);

    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleOpen = () => setIsOpen(!isOpen);

  useEffect(() => {
    // componentDidUpdate logic here
    console.log("OK");
  }, [isOpen, toggleNavbar]);

  const onRouteChanged = () => {
    setIsOpen(!isOpen);
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const data = userData;
    setLoading(true);
    await AuthService.register(data).then(
      () => {
        setLoading(false);
        setSignupForm(false);
        toast.success("Profile create successfully! Please Login Now", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setTimeout(() => {
          setLoginForm(true);
        }, 3000);
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();

        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const onLogin = async (e) => {
    e.preventDefault();
    const data = loginData;
    setLoading(true);
    await AuthService.login(data).then(
      () => {
        setLoading(false);
        setLoginForm(false);
        toast.success("Logged in Successfully!", {
          position: toast.POSITION.TOP_RIGHT,
        });
      },
      (error) => {
        const resMessage =
          (error.response && error.response.data && error.response.data.msg) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    );
  };
  const logout = (e) => {
    localStorage.removeItem("eventNitesUser");
    localStorage.removeItem("eventNitesToken");
    toast.success("Logged out Successfully!", {
      position: toast.POSITION.TOP_RIGHT,
    });
  };

  const classOne = collapsed
    ? "collapse navbar-collapse"
    : "collapse navbar-collapse show";
  const classTwo = collapsed
    ? "navbar-toggler navbar-toggler-right collapsed"
    : "navbar-toggler navbar-toggler-right";
  const menuClass = `dropdown-menu${isOpen ? " show" : ""}`;

  return (
    <>
      <header id="header" className="header-area">
        <div id="navbar" className="elkevent-nav">
          <nav className="navbar navbar-expand-md navbar-light">
            <div className="container" style={{justifyContent: 'center'}}>
              <Link className="navbar-brand" to="/">
                <img src={logo} alt="logo" />
              </Link>

             

              
            </div>
          </nav>
        </div>
      </header>
      {loginForm && (
        <section className="login-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="login-form">
                <h3>Welcome Back!</h3>
                <i class="fas fa-times" onClick={() => setLoginForm(false)}></i>
                <form onSubmit={onLogin}>
                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      value={loginData.email}
                      onChange={(e) =>
                        setLoginData({ ...loginData, email: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={loginData.username}
                      onChange={(e) =>
                        setLoginData({ ...loginData, password: e.target.value })
                      }
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={onLogin}
                  >
                    Login
                  </button>

                  <p>
                    <Link
                      to=""
                      onClick={() => {
                        setLoginForm(false);
                        setSignupForm(true);
                      }}
                      className="pull-left"
                    >
                      Create a new account
                    </Link>

                    <Link
                      to=""
                      style={{ cursor: "pointer" }}
                      className="pull-right"
                    >
                      Forgot your password?
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
      {signupForm && (
        <section className="signup-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="signup-form">
                <h3>Create your Account</h3>
                <i
                  class="fas fa-times"
                  onClick={() => setSignupForm(false)}
                ></i>
                <form onSubmit={onSubmit}>
                  <div className="form-group">
                    <label>Username</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Username"
                      value={userData.username}
                      onChange={(e) =>
                        setUserData({ ...userData, name: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Email</label>
                    <input
                      type="email"
                      className="form-control"
                      placeholder="Email Address"
                      value={userData.email}
                      onChange={(e) =>
                        setUserData({ ...userData, email: e.target.value })
                      }
                    />
                  </div>

                  <div className="form-group">
                    <label>Password</label>
                    <input
                      type="password"
                      className="form-control"
                      placeholder="Password"
                      value={userData.password}
                      onChange={(e) =>
                        setUserData({ ...userData, password: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Phone</label>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Phone"
                      value={userData.username}
                      onChange={(e) =>
                        setUserData({ ...userData, phoneNo: e.target.value })
                      }
                    />
                  </div>
                  <div className="form-group">
                    <label>Address</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Address"
                      value={userData.username}
                      onChange={(e) =>
                        setUserData({ ...userData, address: e.target.value })
                      }
                    />
                  </div>

                  <button
                    type="submit"
                    className="btn btn-primary"
                    onClick={onSubmit}
                  >
                    Signup
                  </button>

                  <p>
                    Already a registered user?{" "}
                    <Link
                      to=""
                      onClick={() => {
                        setSignupForm(false);
                        setLoginForm(true);
                      }}
                    >
                      Login!
                    </Link>
                  </p>
                </form>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default withRouter(Navigation);
