import React, { useState } from "react";

const Subscribe = () => {
  const [term, setTerm] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add logic for handling the form submission with the email in 'term'
    // For example, you can send the email to a server or perform other actions
    console.log("Email submitted:", term);
  };

  return (
    <section className="subscribe-area">
      <div className="container">
        <div className="subscribe-inner">
          <span>Quieres algo extra</span>
          <h2>REGÍSTRATE EN NUESTRA NEWSLETTER</h2>

          <form
            className="newsletter-form"
            data-toggle="validator"
            onSubmit={handleSubmit}
          >
            <input
              type="email"
              className="form-control"
              placeholder="Introduce tu email"
              name="EMAIL"
              required
              autoComplete="off"
              value={term}
              onChange={(e) => setTerm(e.target.value)}
            />
            <button className="btn btn-primary" type="submit">
            Subscríbete
            </button>
            <div id="validator-newsletter" className="form-result"></div>
          </form>
        </div>
      </div>
    </section>
  );
};

export default Subscribe;
