import React,{useEffect,useState} from 'react'
import VenueOne from "../../assets/images/crowd-fans.jpg";
import VenueTwo from "../../assets/images/glowing-stage.jpg";
import VenueThree from "../../assets/images/bunch-flower.jpg";
import "../../assets/css/SingleEvents.css"
import MainBanner from '../HomeFour/MainBanner';
import Footer from '../Common/Footer';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import DataService from '../../services/data.service';
const SingleEvents = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState("");
  const params = useParams()
  const Events = async () => {
    await DataService.getSingleEvents(params.id).then((data) => {
      console.log(data?.data?.data)
      setData(data.data.data);
      setLoading(false);
    });
  }
  useEffect(() => {
    Events();
  }, [])


  return (
    <>
      <MainBanner />
      <div className="venue_single">
        <div className="container">
          <div className="venue_single_inner">
            <div className="venue_single_left">
              <img src={VenueOne} />
            </div>
            <div className="venue_single_right">
              <img src={VenueTwo} />
              <img src={VenueThree} />
              <img src={VenueOne} />
              <img src={VenueTwo} />
            </div>
          </div>

          <div className="venue_single_content">
            <div className="venue_single_one">
              <h2>{data?.title}</h2>
              <div className="hotel_location">
                <p>
                  <i class="icofont-location-pin"></i> {data?.location}
                </p>
              </div>
              <div className="hotel_rating">
                <p>
                  <i class="icofont-star"></i>
                  <i class="icofont-star"></i>
                  <i class="icofont-star"></i>
                  <i class="icofont-star"></i> 74 reviews
                </p>
              </div>
              <div className="about_theSpace">
                <h3>About the Space</h3>
                <p>
                {data?.description}
                </p>
              </div>
              {/* <div className="parking_space">
                  <h3>Parking</h3>
                  <p>Nearby parking lot</p>
                </div> */}
              {/* <div className="Included-booking">
                  <h2>Amenities</h2>
                  <div className="included_inner">
                    <p>
                      <i class="icofont-dining-table"></i> Tables
                    </p>
                    <p>
                      <i class="icofont-chair"></i> Chairs
                    </p>
                    <p>
                      <i class="icofont-newspaper"></i> Speakers
                    </p>
                    <p>
                      <i class="icofont-spoon-and-fork"></i> Dining Plates and
                      Utensils
                    </p>
                    <p>
                      <i class="icofont-ui-wifi"></i> WiFi
                    </p>
                    <p>
                      <i class="icofont-table"></i> Tablecloths
                    </p>
                  </div>
                </div> */}
              {/* <div className="Included-booking">
                  <h2>Features</h2>
                  <div className="included_inner">
                    <p>Kitchen</p>
                    <p>Outdoor Area</p>
                    <p>Restrooms</p>
                    <p>View</p>
                    <p>Freight Elevators</p>
                    <p>Public Transportation</p>
                  </div>
                </div>
                <div className="Included-booking">
                  <h2>Other</h2>
                  <div className="included_inner">
                    <p>Catering Services</p>
                    <p>Catering Services</p>
                  </div>
                </div> */}
            </div>

            <div className="venue_single_two">
              <div className="price_venue">
                <h2>$ {data?.price}/hr</h2>
                <p>5 hr minimum</p>
              </div>

              <div className="main_datearea">
                {/* <div className="venue_datemain">
                    <label>Date and time*</label>
                  </div>
                  <div className="venue_date">
                    <input type="date" />
                  </div> */}
                {/* <div className="venue_time">
                    <input
                      type="time"
                      style={{ marginRight: "8px" }}
                      placeholder="start time"
                    />
                    <input type="time" placeholder="end time" />
                  </div> */}
                <div className="btn_box btn-box22">
                  <div className="btn btn-primary">Start Booking</div>
                </div>
                <div className="bottom_text">
                  <h2>Jillian typically respond within 12 hrs</h2>
                  <p>You won’t be charged yet.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default SingleEvents