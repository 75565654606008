import axios from "axios";
import AuthService from "../services/auth.service";

const API_URL =
  process.env.NODE_ENV != "production"
    ? "https://opm-stream.onrender.com/"
    : "https://opm-stream.onrender.com/";

axios.interceptors.request.use(function (config) {
  const token = AuthService.getCurrentUserTokken();
  config.headers.Authorization = "Bearer " + token;

  return config;
});
axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 401) {
      localStorage.removeItem("user");
      window.location.href = "/login";
    }
    return Promise.reject(error);
  }
);

const getArtists = () => {
  return axios.get(API_URL + "api/artist/getAll");
}

const getEvents = () => {
  return axios.get(API_URL + "api/event/getAll");
};
const getSingleEvents = (id) => {
  return axios.get(API_URL + "api/event/getById/"+id);
}
const getAllVenue = () => {
  return axios.get(API_URL + "api/venue/getAll");
};
const getSingleVenue = (id) => {
  return axios.get(API_URL + "api/venue/getById/"+id);

};
const DataService = {
  getArtists,
  getEvents,
  getSingleEvents,
  getAllVenue,
  getSingleVenue,

};
export default DataService;
