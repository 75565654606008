import React, { useEffect, useState } from "react";
import lax from "lax.js";
import { Link } from "react-router-dom";
import LaxDiv from "../Shared/LaxDiv";
import DataService from "../../services/data.service";
import { toast } from "react-toastify";

const Speakers = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const getData = () => {
    DataService.getArtists()
      .then((data) => {
        console.log(data?.data?.data);
        setData(data.data.data);
        setLoading(false);
      })
      .catch((error) => {
        const resMessage =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        setLoading(false);
        toast.error(resMessage, {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    getData();
    lax.setup();

    const handleScroll = () => {
      lax.update(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll, false);

    lax.update(window.scrollY);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="speakers-area ptb-120 pb-0">
      <div className="container">
        <div className="section-title">
          <span>Listen to the Event Artists</span>
          <h2>Who's Speaking</h2>
          <div className="bar"></div>
          <LaxDiv text="Artists" dataPreset="driftRight" />
          <Link to="#" className="btn btn-primary">
            View More Artists
          </Link>
        </div>
      </div>

      <div className="row m-0">
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-speakers">
            {/* {
                    item?.images ? 
                    <img
                      src={item?.images[0]?.url}
                      alt="Artist"
                    /> :  
                    <img
                      src={require("../../assets/images/star1.jpg")}
                      alt="Artist"
                    />
                  } */}
            <img src={require("../../assets/images/star1.jpg")} alt="Artist" />

            <div className="speakers-content">
              <h3>
                <Link to="#">Jordan</Link>
              </h3>
              <span>Hip Hop</span>
            </div>
            <Link to="/single-artist">
              <Link to="/single-artist">
                <button className="btn btn-primary">View & Book</button>
              </Link>
            </Link>
            <ul>
              <li>
                <Link
                  to="https://www.facebook.com/"
                  target="_blank"
                  className="facebook"
                >
                  <i className="icofont-facebook"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://twitter.com/"
                  target="_blank"
                  className="twitter"
                >
                  <i className="icofont-twitter"></i>
                </Link>
              </li>
              <li>
                <Link
                  to="https://www.instagram.com/"
                  target="_blank"
                  className="instagram"
                >
                  <i className="icofont-instagram"></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Speakers;
