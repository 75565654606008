import React, { useState } from "react";
import { Link } from "react-router-dom";
import ModalVideo from "react-modal-video";

const MainBanner = () => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <ModalVideo
        channel="youtube"
        isOpen={isOpen}
        videoId="cRXm1p-CNyk"
        onClose={() => setIsOpen(false)}
      />

      <div className="main-banner item-bg2">
        <div className="d-table">
          <div className="d-table-cell">
            <div className="container">
              <div className="main-banner-content banner-content-center">
                <p>
                  Estás preparado <span>para</span>  participar?
                </p>
                <h1>
                  Un Evento de <span>Emprendimiento</span> <br /> Único en <b>2</b>
                  <b>0</b>
                  <b>2</b>
                  <b>0</b>
                </h1>
                <ul>
                  <li>
                    <i className="icofont-compass"></i> Gijón, España
                  </li>
                  <li>
                    <i className="icofont-calendar"></i>2 Marzo, 2024
                  </li>
                </ul>
                <div className="button-box">
                  <Link to="#" className="btn btn-primary">
                    Comprar Tickets Ahora!
                  </Link>
                  <Link
                    onClick={(e) => {
                      e.preventDefault();
                      openModal();
                    }}
                    to="#"
                    className="video-btn popup-youtube"
                  >
                    <i className="icofont-ui-play"></i>Ver Video del Evento
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </div>
    </>
  );
};

export default MainBanner;
