import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel3";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";

const options = {
  loop: true,
  nav: false,
  dots: true,
  margin: 30,
  autoplayHoverPause: true,
  autoplay: true,
  navText: [
    "<i class='icofont-rounded-left'></i>",
    "<i class='icofont-rounded-right'></i>",
  ],
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 2,
    },
    1200: {
      items: 3,
    },
  },
};

const LatestNews = () => {
  useEffect(() => {
    lax.setup();

    const handleScroll = () => {
      lax.update(window.scrollY);
    };

    document.addEventListener("scroll", handleScroll, false);

    lax.update(window.scrollY);

    // Cleanup the event listener on component unmount
    return () => {
      document.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="blog-area ptb-120 bg-image">
      <div className="container">
        <div className="section-title">
          <span>ÚLTIMAS ACTUALIZACIONES</span>
          <h2>
          ÚLTIMAS NOTICIAS
          </h2>

          <LaxDiv text="Blog" dataPreset="driftLeft" />

          <Link to="/s" className="btn btn-primary">
          Ver Últimas Noticias
          </Link>

          <div className="bar"></div>
        </div>

        <div className="row">
          <OwlCarousel
            className="blog-slides owl-carousel owl-theme"
            {...options}
          >
            <div className="col-lg-12 col-md-12">
              <div className="single-blog-post">
                <div className="blog-image">
                  <Link to="/s">
                    <img
                      src={require("../../assets/images/blog1.jpg")}
                      alt="blog"
                    />
                  </Link>

                  <div className="post-tag">
                    <Link to="/s">Technology</Link>
                  </div>
                </div>

                <div className="blog-post-content">
                  <span className="date">25 Feb, 2024</span>
                  <h3>
                    <Link to="/s">
                      The Most Popular New top Business Apps
                    </Link>
                  </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum.
                  </p>
                  <Link to="/s" className="read-more-btn">
                    Read More <i className="icofont-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="single-blog-post">
                <div className="blog-image">
                  <Link to="/s">
                    <img
                      src={require("../../assets/images/blog2.jpg")}
                      alt="blog"
                    />
                  </Link>

                  <div className="post-tag">
                    <Link to="/s">Agency</Link>
                  </div>
                </div>

                <div className="blog-post-content">
                  <span className="date">27 Feb, 2024</span>
                  <h3>
                    <Link to="/s">
                      The Best Marketing top Management Tools
                    </Link>
                  </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum.
                  </p>
                  <Link to="/s" className="read-more-btn">
                    Read More <i className="icofont-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="single-blog-post">
                <div className="blog-image">
                  <Link to="/s">
                    <img
                      src={require("../../assets/images/blog3.jpg")}
                      alt="blog"
                    />
                  </Link>

                  <div className="post-tag">
                    <Link to="/s">IT</Link>
                  </div>
                </div>

                <div className="blog-post-content">
                  <span className="date">28 Feb, 2024</span>
                  <h3>
                    <Link to="/s">
                      3 WooCommerce Plugins to Boost Sales
                    </Link>
                  </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum.
                  </p>
                  <Link to="/single-blog" className="read-more-btn">
                    Read More <i className="icofont-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12">
              <div className="single-blog-post">
                <div className="blog-image">
                  <Link to="/s">
                    <img
                      src={require("../../assets/images/blog4.jpg")}
                      alt="blog"
                    />
                  </Link>

                  <div className="post-tag">
                    <Link to="/s">IT</Link>
                  </div>
                </div>

                <div className="blog-post-content">
                  <span className="date">28 Feb, 2024</span>
                  <h3>
                    <Link to="/s">
                      How To Setup Redux In React Next Application
                    </Link>
                  </h3>
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum.
                  </p>
                  <Link to="/s" className="read-more-btn">
                    Read More <i className="icofont-double-right"></i>
                  </Link>
                </div>
              </div>
            </div>
          </OwlCarousel>
        </div>
      </div>
    </section>
  );
};

export default LatestNews;
