import React from "react";
import MainBanner from "../Speakers/MainBanner";
import Speakers from "../Speakers/SpeakersThree";
import Footer from "../Common/Footer";

const SpeakersThree = () => {
  return (
    <React.Fragment>
      <MainBanner />
      <Speakers />
      <Footer />
    </React.Fragment>
  );
};

export default SpeakersThree;
