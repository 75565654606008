import React from 'react'
import "../../assets/css/SingleArtists.css";
import Footer from '../Common/Footer';

const SingleArtists = () => {
    return (
        <>
            <div className="banner-artist-page">
                <div className="artist-image"></div>
            </div>

            <div className="artist-sec-2">
                <div className="container">
                    <div className="artist-column-main">
                        <div className="leftS-artist-col">
                            <div className="left-artist">
                                <h4>Details</h4>
                                <div className="left-artist-content">
                                    <span>Professional Singer</span>
                                </div>
                                <div className="left-artist-content">
                                    <span><b>Type :</b> Hip Hop</span>
                                </div>
                                <div className="left-artist-content">
                                    <span><b>Price :</b> $98000</span>
                                </div>
                                <div className="book-artist-btn">
                                    <button className="main-btn btn-3"> Book Now</button>
                                </div>
                            </div>
                        </div>
                        <div className="rightS-artist-col">
                            <div className="rightS-artist-content">
                                <h3>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lore
                                </h3>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries, but
                                    also the leap into electronic typesetting, remaining
                                    essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum
                                    passages, and more recently with desktop publishing software
                                    like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries, but
                                    also the leap into electronic typesetting, remaining
                                    essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum
                                    passages, and more recently with desktop publishing software
                                    like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                                <p>
                                    Lorem Ipsum is simply dummy text of the printing and
                                    typesetting industry. Lorem Ipsum has been the industry's
                                    standard dummy text ever since the 1500s, when an unknown
                                    printer took a galley of type and scrambled it to make a type
                                    specimen book. It has survived not only five centuries, but
                                    also the leap into electronic typesetting, remaining
                                    essentially unchanged. It was popularised in the 1960s with
                                    the release of Letraset sheets containing Lorem Ipsum
                                    passages, and more recently with desktop publishing software
                                    like Aldus PageMaker including versions of Lorem Ipsum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default SingleArtists