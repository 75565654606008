import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const MainBanner = () => {
  const [time, setTime] = useState({
    days: "",
    hours: "",
    minutes: "",
    seconds: "",
  });

  const makeTimer = () => {
    let endTime = new Date("January 1, 2023 17:00:00 PDT");
    let endTimeParse = Date.parse(endTime) / 1000;
    let now = new Date();
    let nowParse = Date.parse(now) / 1000;
    let timeLeft = endTimeParse - nowParse;
    let days = Math.floor(timeLeft / 86400);
    let hours = Math.floor((timeLeft - days * 86400) / 3600);
    let minutes = Math.floor((timeLeft - days * 86400 - hours * 3600) / 60);
    let seconds = Math.floor(
      timeLeft - days * 86400 - hours * 3600 - minutes * 60
    );
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    setTime({
      days,
      hours,
      minutes,
      seconds,
    });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      makeTimer();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="main-banner slideshow-banner">
      <div className="d-table">
        <div className="d-table-cell">
          <div className="container">
            <div className="main-banner-content">
              <div className="event-countdown">
                <div id="timer">
                  <div id="days">
                    {time.days} <span>Days</span>
                  </div>
                  <div id="hours">
                    {time.hours} <span>Hours</span>
                  </div>
                  <div id="minutes">
                    {time.minutes} <span>Minutes</span>
                  </div>
                  <div id="seconds">
                    {time.seconds} <span>Seconds</span>
                  </div>
                </div>
              </div>
              <h1>
                Un Evento de <span>Emprendimiento</span> <br /> Único en <b>2</b>
                <b>0</b>
                <b>2</b>
                <b>0</b>
              </h1>
              <ul>
                <li>
                  <i className="icofont-compass"></i> Yellow Street, United
                  State
                </li>
                <li>
                  <i className="icofont-calendar"></i>2 Marzo, 2024
                </li>
              </ul>
              <div className="button-box">
                <Link to="#" className="btn btn-primary">
                  Comprar Tickets Ahora!
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="shape1">
        <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
      </div>
      <div className="shape2 rotateme">
        <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
      </div>
      <div className="shape3 rotateme">
        <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
      </div>
      <div className="shape4">
        <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
      </div>

      <ul className="slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>
    </div>
  );
};

export default MainBanner;
