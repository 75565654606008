import React from "react";
import { Link } from "react-router-dom";

const WhyUs = () => {
  return (
    <section className="why-choose-us">
      <div className="row m-0">
        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-box">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="content">
                  <div className="icon">
                    <i className="icofont-headphone-alt-1"></i>
                  </div>
                  <h3>Ponentes Top</h3>
                  <p>
                    Contamos con algunos de los mejores ponentes del panorama
                    nacional, con méritos contrastados en sus respectivos
                    campos.
                  </p>

                  {/* <Link to="#" className="btn btn-primary">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-box">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="content">
                  <div className="icon">
                    <i className="icofont-network-tower"></i>
                  </div>
                  <h3>Networking</h3>
                  <p>
                    Los asistentes tendrán la oportunidad de conocer a personas
                    con sus mismos intereses e inquietudes, así como interactuar
                    con los ponentes durante una comida exclusiva.
                  </p>

                  {/* <Link to="#" className="btn btn-primary">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-box">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="content">
                  <div className="icon">
                    <i className="icofont-users-social"></i>
                  </div>
                  <h3>Gente Nueva</h3>
                  <p>
                    Salir de tu zona de confort, con las personas que conoces y
                    estás cómodo, es el primer paso para llegar al siguiente
                    nivel. Conocer, aprender y compartir nuevas formas de pensar
                  </p>

                  {/* <Link to="#" className="btn btn-primary">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="col-lg-3 col-sm-6 p-0">
          <div className="single-box">
            <div className="d-table">
              <div className="d-table-cell">
                <div className="content">
                  <div className="icon">
                    <i className="icofont-wink-smile"></i>
                  </div>
                  <h3>Diviértete</h3>
                  <p>
                    Pásatelo en grande en un evento de emprendimiento único,
                    donde descubrirás todos los secretos detrás del mundo del
                    emprendimiento
                  </p>

                  {/* <Link to="#" className="btn btn-primary">
                    Read More
                  </Link> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ul className="slideshow">
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
        <li>
          <span></span>
        </li>
      </ul>
    </section>
  );
};

export default WhyUs;
