import React, { useState, useEffect } from "react";
import AppRouter from "./Routes";
import Preloader from "./components/Shared/Preloader";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/bootstrap.min.css";
import "./assets/css/icofont.min.css";
import "./assets/css/animate.min.css";
import "../node_modules/react-modal-video/css/modal-video.min.css";
import "./assets/css/style.css";
import "./assets/css/responsive.css";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const demoAsyncCall = () => {
      return new Promise((resolve) => setTimeout(() => resolve(), 2000));
    };

    const fetchData = async () => {
      await demoAsyncCall();
      setLoading(false);
    };

    fetchData();
  }, []);

  return (
    <React.Fragment>
      {loading ? <Preloader /> : ""}
      <ToastContainer />
      <AppRouter />
    </React.Fragment>
  );
};

export default App;
