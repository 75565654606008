import React from "react";
import { Link } from "react-router-dom";
import lax from "lax.js";
import LaxDiv from "../Shared/LaxDiv";

class EventSchedules extends React.Component {
  constructor(props) {
    super(props);
    lax.setup();

    document.addEventListener(
      "scroll",
      function (x) {
        lax.update(window.scrollY);
      },
      false
    );

    lax.update(window.scrollY);
  }

  openTabSection = (evt, tabNmae) => {
    let i, tabcontent, tablinks;
    tabcontent = document.getElementsByClassName("tabs_item");
    for (i = 0; i < tabcontent.length; i++) {
      tabcontent[i].style.display = "none";
    }

    tablinks = document.getElementsByTagName("li");
    for (i = 0; i < tablinks.length; i++) {
      tablinks[i].className = tablinks[i].className.replace("current", "");
    }

    document.getElementById(tabNmae).style.display = "block";
    evt.currentTarget.className += "current";
  };

  render() {
    return (
      <section className="schedule-area bg-image ptb-120">
        <div className="container">
          <div className="section-title">
            <span>ORGANIZACIÓN</span>
            <h2>Información de horarios del Evento</h2>

            <LaxDiv text="Events" dataPreset="driftLeft" />

            <Link to="#" className="btn btn-primary">
              Comprar tickets ahora!
            </Link>

            <div className="bar"></div>
          </div>

          <div className="row">
            <div className="col-lg-12">
              <div className="tab">
                <ul className="tabs active">
                  <li
                    onClick={(e) => this.openTabSection(e, "tab1")}
                    className="current"
                  >
                    <Link to="#">
                      Información de horarios del Evento
                      {/* <span>4 April 2024</span> */}
                    </Link>
                  </li>
                </ul>

                <div className="tab_content">
                  <div id="tab1" className="tabs_item">
                    <ul className="accordion">
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 10:30 -
                                11:00
                              </li>
                              <li>
                                <strong>Apertura de Puertas</strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 11:00 -
                                11:30
                              </li>
                              <li>
                                <strong>Introducción</strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 11:30 -
                                12:15
                              </li>
                              <li>
                                <strong>
                                  Primera Ponencia (Eficiencia y Mejor Versión)
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 12:15 -
                                13:00
                              </li>
                              <li>
                                <strong>
                                  Segunda Ponencia (La creación de un Imperio y
                                  su Venta)
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 13:00 -
                                13:45
                              </li>
                              <li>
                                <strong>
                                  Tercera Ponencia (Emprendimiento desde Cero)
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 13:45 -
                                15:30
                              </li>
                              <li>
                                <strong>Comida y Networking</strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 15:30 -
                                16:15
                              </li>
                              <li>
                                <strong>
                                  Cuarta Ponencia (Marca Personal y redes
                                  sociales)
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 16:15 -
                                17:15
                              </li>
                              <li>
                                <strong>
                                  Presentaciones Emprendedores Asturianos
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 16:15 -
                                18:00
                              </li>
                              <li>
                                <strong>
                                  Mesa Redonda: Emprender en Deporte
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 18:00 -
                                18:30
                              </li>
                              <li>
                                <strong>
                                  Mesa Redonda: Streetwear, de España al Mundo
                                </strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 18:30 -
                                20:00
                              </li>
                              <li>
                                <strong>Cierre Evento</strong>
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                      <li className="accordion-item">
                        <Link className="accordion-title" to="#">
                          <div className="schedule-info">
                            <ul>
                              <li>
                                <i className="icofont-wall-clock"></i> 00:00 -
                                Cierre Post-Evento
                              </li>
                            </ul>
                          </div>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12">
              <div className="btn-box">
            
                <a href="https://www.instagram.com/asturiasemprende/" target="_blank" rel="" className="btn btn-secondary">
                  Connect Via Instagram
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="shape1">
          <img src={require("../../assets/images/shapes/1.png")} alt="shape1" />
        </div>
        <div className="shape2 rotateme">
          <img src={require("../../assets/images/shapes/2.png")} alt="shape2" />
        </div>
        <div className="shape3 rotateme">
          <img src={require("../../assets/images/shapes/3.png")} alt="shape3" />
        </div>
        <div className="shape4">
          <img src={require("../../assets/images/shapes/4.png")} alt="shape4" />
        </div>
      </section>
    );
  }
}

export default EventSchedules;
