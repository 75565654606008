import React, { useEffect } from 'react';
import lax from 'lax.js';
import LaxButton from '../Shared/LaxButton';

const About = () => {
    useEffect(() => {
        lax.setup();

        const handleScroll = () => {
            lax.update(window.scrollY);
        };

        document.addEventListener('scroll', handleScroll, false);
        lax.update(window.scrollY);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('scroll', handleScroll, false);
        };
    }, []); // Empty dependency array to run the effect only once on mount

    return (
        <section className="about-area-two ptb-120 bg-image">
            <div className="container">
                <div className="row h-100 align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content">
                            <span>ÚNETEALEVENTO</span>
                            <h2>Traemos el Emprendimiento a Asturias</h2>

                            <p>It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.</p>

                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.</p>

                            <div className="signature">
                                <img src={require("../../assets/images/signature.png")} alt="about" />
                            </div>
                        </div>
                    </div>

                    <div className="col-lg-6">
                        <div className="about-image">
                            <img src={require("../../assets/images/about3.jpg")} className="about-img1" alt="about" />

                            <LaxButton />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default About;
