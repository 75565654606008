import React from 'react'
import "../../assets/css/SearchVenue.css";
import Guys from "../../assets/images/guys.jpg";
import venue from "../../assets/images/event.webp";
import Footer from '../Common/Footer';
import MainBanner from '../HomeFour/MainBanner';
const SearchVenue = () => {
    return (
        <>
            <MainBanner />
            <div className='container'>
            <div className="top-sec">
                <div className="main-banner-home">
                    <div className="leftS-banner-home">
                        <h1>Rent an event venue in New York, NY</h1>
                        <p>
                            Discover thousands of unique event venues for rent perfect for
                            your event.
                        </p>
                        <div class="form-floating">
                            <input
                                type="search"
                                class="form-control"
                                id="floatingPassword"
                                placeholder="Password"
                            />
                            <label for="floatingPassword">Enter Your Activity</label>
                        </div>
                        <div class="form-floating second-serach-bar">
                            <input
                                type="search"
                                class="form-control"
                                id="floatingPassword"
                                placeholder="Password"
                            />
                            <label for="floatingPassword">Enter a City or Address</label>
                        </div>
                        <div className="banner-btn">
                            <button className="main-btn btn-fullW">Search</button>
                        </div>
                    </div>
                    <div className="rightS-banner-home">
                        <img src={Guys} />
                    </div>
                </div>
            </div>
            </div>
            <div className="venue-sec-main">
                <div className="container">
                    <div className="venue-headiong">
                        <h2>Popular event venues</h2>
                        <p>Explore all 640+ event venues in New York. See All ›</p>
                    </div>
                    <div className="venueS-main-column">
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>
                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>
                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>
                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>
                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>
                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                        <div className="venueS-sub-column">
                            <div className="venueS-col-img">
                                <img src={venue} />
                                <div className="price-box">
                                    <span className="text-price">from</span>
                                    <span className="price-number">$250</span>
                                    <span className="text-hour">/hr</span>
                                </div>
                                <div className="rating-star">
                                    <span className="icon-rating">
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                        <i class="fas fa-star"></i>
                                    </span>
                                    <span className="rating-star-No">90</span>
                                </div>
                            </div>
                            <div className="VenueS-content2R">
                                <span className="location-content">
                                    <i class="fas fa-map-marker-alt"></i> Midtown, New York, NY
                                </span>
                            </div>
                            <div className="venueS-column-content">
                                <h5>Convertible Space in the Heart of Chelsea</h5>

                                <p>
                                    can transform into whatever you want it to be. From art
                                    gallery shows to yoga sessions to a screening or cocktail
                                    networking event, we also support
                                </p>
                                <div className="venueS-single-btn">
                                    <button className="main-btn"> Read More</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="main-button-showMore">
                        <button className="main-btn"> Show More</button>
                    </div>
                </div>
            </div>
            {/* <div className="text-section-main">
        <div className="container">
          <div className="text-sub-content">
            <h2>Discover unforgettable event venues in New York City</h2>
            <p>
              When it comes to hosting a party, corporate retreat, workshop, or
              any other type of event, New York City offers endless
              possibilities for venues. After all, the location sets the tone
              for your event and can make or break the entire experience. That’s
              why choosing the best event space in NYC is crucial, whether you
              need a speakeasy for a themed birthday party or a chef’s kitchen
              for a wine tasting or cooking class.{" "}
            </p>
            <p>
              When it comes to hosting a party, corporate retreat, workshop, or
              any other type of event, New York City offers endless
              possibilities for venues. After all, the location sets the tone
              for your event and can make or break the entire experience. That’s
              why choosing the best event space in NYC is crucial, whether you
              need a speakeasy for a themed birthday party or a chef’s kitchen
              for a wine tasting or cooking class.{" "}
            </p>
          </div>
          <div className="text-sub-content">
            <h2>Discover unforgettable event venues in New York City</h2>
            <p>
              When it comes to hosting a party, corporate retreat, workshop, or
              any other type of event, New York City offers endless
              possibilities for venues. After all, the location sets the tone
              for your event and can make or break the entire experience. That’s
              why choosing the best event space in NYC is crucial, whether you
              need a speakeasy for a themed birthday party or a chef’s kitchen
              for a wine tasting or cooking class.{" "}
            </p>
            <p>
              When it comes to hosting a party, corporate retreat, workshop, or
              any other type of event, New York City offers endless
              possibilities for venues. After all, the location sets the tone
              for your event and can make or break the entire experience. That’s
              why choosing the best event space in NYC is crucial, whether you
              need a speakeasy for a themed birthday party or a chef’s kitchen
              for a wine tasting or cooking class.{" "}
            </p>
          </div>
        </div>
      </div> */}
            <div className="venueS-main-review">
                <div className="container">
                    <div className="review-tittle">
                        <h2>Reviews For venues</h2>
                    </div>
                    <div className="section-flex">
                        <div className="review-sub-sec">
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>

                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                            <div className="review-main-column">
                                <div className="review-img">
                                    <img src={venue} />
                                </div>
                                <div className="review-main-content">
                                    <div className="reviwe-top-content">
                                        <h5>Gab L</h5>
                                        <span className="icon-rating">
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                            <i class="fas fa-star"></i>
                                        </span>
                                    </div>
                                    <h6>The Empire Room</h6>
                                    <p>
                                        so accommodating and such a pleasant person to work with.
                                        The space was just what we needed for our Friendsgiving and
                                        was clean...
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="side-bar-venue">
                            <div className="side-bar-venue-content">
                                <h4>Find venues near New York, NY</h4>
                                <div className="side-bar-item">
                                    <div className="side-bar-list1">
                                        <ul>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>

                                        </ul>
                                    </div>
                                    <div className="side-bar-list1">
                                        <ul>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="side-bar-venue-content side-bar-venue-content2">
                                <h4>Similar searches in New York</h4>
                                <div className="side-bar-item">
                                    <div className="side-bar-list1">
                                        <h6>Similar searches</h6>
                                        <ul>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>

                                        </ul>
                                    </div>
                                    <div className="side-bar-list1">
                                        <h6>Nearby cities</h6>
                                        <ul>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>

                                        </ul>
                                    </div>
                                    <div className="side-bar-list1">
                                        <h6>Popular searches</h6>
                                        <ul>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>
                                            <a href="#">  <li>
                                                {" "}
                                                <i class="fas fa-hand-point-right"></i>SoHo, NY
                                            </li></a>

                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>

    )
}

export default SearchVenue