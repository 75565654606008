import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Lugar del evento</h3>
              <span>
                <i className="icofont-calendar"></i> 2 Marzo, 2024
              </span>

              <p className="location">
                <i className="icofont-google-map"></i> Universidad Laboral de
                Gijón, Gijón, Asturias
              </p>

              <a
                href="mailto:emprendeasturias@emprendeasturias.com"
                className="contact-authority"
              >
                <i className="icofont-phone"></i> Contáctanos
              </a>
            </div>
          </div>

          <div className="col-lg-6 col-md-6">
            <div className="single-footer-widget">
              <h3>Redes sociales</h3>
              <p>
                No te pierdas nada! Mira todas las novedades en nuestras redes
                sociales.
              </p>

              <ul className="social-links">
                <li>
                  <a
                    href="https://www.instagram.com/asturiasemprende/"
                    className="instagram"
                    target="_blank"
                    title="Insta"
                    rel="noreferrer"
                  >
                    <i className="icofont-instagram"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-lg-12">
            <div className="copyright-area">
              <div className="logo">
                <Link to="/">
                  <img
                    src={require("../../assets/images/eventnites_logo.png")}
                    alt="logo"
                  />
                </Link>
              </div>

              <p className="mt-5">
                Copyright <i className="icofont-copyright"></i> 2024. All rights
                reserved
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
