import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import lax from "lax.js";

const GoldSponsors = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = ReactDOM.findDOMNode(elementRef.current);
    lax.addElement(element);

    return () => {
      lax.removeElement(element);
    };
  }, []);

  return (
    <h3 className="lax" data-lax-preset="driftLeft" ref={elementRef}>
      NIVEL ORO
    </h3>
  );
};

export default GoldSponsors;
