import React from "react";
import MainBanner from "../schedule/MainBanner";
import EventSchedulesFour from "../schedule/EventSchedulesFour";
import Footer from "../Common/Footer";

const ScheduleFour = () => {
  return (
    <>
      <MainBanner />
      <EventSchedulesFour />
      <Footer />
    </>
  );
};

export default ScheduleFour;
