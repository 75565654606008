import React from 'react';
import MainBanner from '../schedule/MainBanner';
import EventSchedulesOne from '../schedule/EventSchedulesOne';
import Footer from '../Common/Footer';

const ScheduleOne = () => {
  return (
    <>
      <MainBanner />
      <EventSchedulesOne />
      <Footer />
    </>
  );
};

export default ScheduleOne;
