import React from "react";
import MainBanner from "../schedule/MainBanner";
import EventSchedulesTwo from "../schedule/EventSchedulesTwo";
import Footer from "../Common/Footer";

const ScheduleTwo = () => {
  return (
    <>
      <MainBanner />
      <EventSchedulesTwo />
      <Footer />
    </>
  );
};

export default ScheduleTwo;
