import React from "react";
import { Link } from "react-router-dom";

const BuyTicket = () => {
  return (
    <section className="buy-tickets-area ptb-120">
      <div className="buy-tickets">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title">
                <span>Hurry Up!</span>
                <h2>Reserva tu asiento</h2>
                <p>
                Participa en uno de los
 eventos de emprendimiento del año. Empieza a tomar decisiones desde hoy.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="buy-ticket-btn">
                <a href="mailto:emprendeasturias@emprendeasturias.com" className="btn btn-primary">
                Compra tu entrada!
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default BuyTicket;
