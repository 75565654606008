import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom";
import lax from "lax.js";

const PlatinumSponsors = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = ReactDOM.findDOMNode(elementRef.current);
    lax.addElement(element);

    return () => {
      lax.removeElement(element);
    };
  }, []);

  return (
    <h3 className="lax" data-lax-preset="driftRight" ref={elementRef}>
      NIVEL PLATINO
    </h3>
  );
};

export default PlatinumSponsors;
