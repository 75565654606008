import axios from "axios";

const API_URL =
  process.env.NODE_ENV != "production"
    ? "https://opm-stream.onrender.com/"
    : "https://opm-stream.onrender.com/";

const register = (data) => {
  return axios.post(API_URL + "api/user/register", data);
};
const login = (data) => {
  return axios.post(API_URL + "api/user/login", data).then((response) => {
    if (response.data.data.accessToken) {
      localStorage.setItem(
        "eventNitesUser",
        JSON.stringify(response.data.data.userData)
      );
      localStorage.setItem(
        "eventNitesToken",
        JSON.stringify(response.data.data.accessToken)
      );
    }

    return response.data;
  });
};

const logout = () => {
  localStorage.removeItem("eventNitesUser");
  localStorage.removeItem("eventNitesToken");

  return axios.post(API_URL + "signout").then((response) => {
    return response.data;
  });
};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("eventNitesUser"));
};
const getCurrentUserTokken = () => {
  return JSON.parse(localStorage.getItem("eventNitesToken"));
};

const AuthService = {
  register,
  login,
  logout,
  getCurrentUser,
  getCurrentUserTokken,
};

export default AuthService;
